import React, {useEffect, useState} from 'react';
import { getGuestsStatuses } from '../api';
import TopMenu from '../TopMenu';
import { parseJwt } from '../parseJwt';
import Table from '@mui/joy/Table';

const SaveTheDate = ({token, onLogout}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [errorState, setErrorState] = useState(false);

    const parsedToken = parseJwt(token);

    useEffect(() => {
        const intervalId = setInterval(() => {
            onLogout();
        }, parsedToken.exp * 1000 - Date.now());

        return () => clearInterval(intervalId);
    }, [parsedToken, onLogout, token]);

    useEffect(() => {
        getGuestsStatuses(token).then((content) => {
            setContent(content);
            setIsLoading(false);
        }).catch((err) => {
            if(err.response?.status === 401) {
                onLogout();
            }
            console.log(err);
            setErrorState(true);
        });
    }, [token, onLogout]);

    if(!token || parsedToken.exp * 1000 < Date.now()) {
        onLogout();
        return null;
    }

    if(errorState) {
        return <div>Something went wrong, please refresh</div>;
    }

    return (
        <div style={{ 
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundSize: "200px",
        }}>
            <TopMenu onLogout={() => onLogout()} />
            {isLoading && <div> Loading.. </div>}
            {content && <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}>
                <h1>Guest Stats</h1>
                <p><strong>{content.coming} are coming,</strong> and {content.notComing} are not coming</p>
                <Table hoverRow style={{
                    maxWidth: "800px",
                    width: "90%",
                }}>
                    <thead>
                        <tr>
                            <th style={{ width: '40%' }}>Name</th>
                            <th>isComing?</th>
                            <th>Guests Number</th>
                            <th>Diet</th>
                        </tr>
                    </thead>
                    <tbody> 
                    {content.items.map((item) => (
                        <tr key={item.name}>
                            <td>{item.name}</td>
                            <td>{item.rsvpStatus}</td>
                            <td>{item.guestCount}</td>
                            <td>{item.foodStatus}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                
            </div>}
        </div>);
};

export default SaveTheDate;
