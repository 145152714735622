import React, { createContext, useContext, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';


export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
    var searchParams = new URLSearchParams(window.location.search);
    const [language, setLanguage] = useState(searchParams.get('lang')?? 'en');
    const dir = language === 'he'? 'rtl': 'ltr';

    const onSetLanguage = (newLanguage) => {
        var searchParams = new URLSearchParams(window.location.search);
        searchParams.set('lang', newLanguage);
        window.location.search = searchParams.toString();

        setLanguage(newLanguage);
    }

    return (
        <LanguageContext.Provider value={{ language, setLanguage: onSetLanguage, dir }}>
        {children}
        </LanguageContext.Provider>
    );
};

export const LanguageSelector = () => {
    const {language, setLanguage} = useContext(LanguageContext);
    return <Dropdown>
        <MenuButton
            variant="plain"
            color="primary"
            size="lg"
            style={{
                width: "5vh",
            }}
        >
            <LanguageIcon />
        </MenuButton>
        <Menu>
            <MenuItem selected={language === 'en'} onClick={() => setLanguage("en")}>🇺🇸</MenuItem>
            <MenuItem selected={language === 'he'} onClick={() => setLanguage("he")}>🇮🇱</MenuItem>
            <MenuItem selected={language === 'ko'} onClick={() => setLanguage("ko")}>🇰🇷</MenuItem>
        </Menu>
    </Dropdown>
};