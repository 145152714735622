// English
const en = {
    broomsName: "Bar",
    bridesName: "Hansol",
    usernamePlaceholder: "Username",
    passwordPlaceholder: "Password",
    loginButton: "Let me in",
};

// Korean
export const ko = {
    broomsName: "Bar",
    bridesName: "Hansol",
    usernamePlaceholder: "사용자 이름",
    passwordPlaceholder: "비밀번호",
    loginButton: "Let me in",
};

// Hebrew
export const he = {
    broomsName: "Bar",
    bridesName: "Hansol",
    usernamePlaceholder: "שם משתמש",
    passwordPlaceholder: "סיסמה",
    loginButton: "תן לי להיכנס",
};

export const template = { en, ko, he };