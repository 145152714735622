import React, { useEffect } from "react";
import anime from "animejs";
import theme from '../Theme';

export const HeartComponent = () => {
  const animation = () => {
        anime({
        targets: "svg path",
        strokeDashoffset: [anime.setDashoffset, 0],
        easing: "cubicBezier(.5, .05, .1, .3)",
        duration: 1000,
        delay: function (el, i) {
            return i * 1000;
        },
        loop: true,
        });
    }

    useEffect(() => {
        animation();
    }, [])

    return <svg
          height="100%"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          clipRule="evenodd"
          style={{
            fill: "none",
            stroke: theme.palette.primary[500],
          }}
          viewBox="0 0 24 24"
        >
            <path
                strokeWidth="2"
                d="M12 21.593c-5.63-5.539-11-10.297-11-14.402 0-3.791 3.068-5.191 5.281-5.191 1.312 0 4.151.501 5.719 4.457"
            />
            <path
                strokeWidth="2"
                d="M12 21.593c5.63-5.539 11-10.297 11-14.402 0-3.791-3.068-5.191-5.281-5.191-1.312 0-4.151.501-5.719 4.457"
            />
        </svg>
}
