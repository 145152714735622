const en = { 
    pretitle: 'for the wedding of',
    title: 'Bar Miliavsky and Hansol Kim',
    backupName: 'Dear guest',
    saveTheDate: '<date>',
    invitation: 'We are excited to invite you to our wedding!<br>we would love to share our special day with you.',
    locationName: '<locationName>',
    location: '<locationAdress>',
    food: 'Food: American cuisine with surprises from around the world',
    question: 'Will you be joining us?',
    yes: 'Of course',
    no: 'Can’t Make It',
    detailsQuestion: 'That’s great!<br>Can you share<br>a few more details?<br>pst... Kids are welcome!',
    numberOfGuests: 'Total Guests',
    foodPreference: 'Diet',
    everything: 'Everything',
    kosher: 'Kosher',
    vegan: 'Vegan',
    vegetarian: 'Vegetarian',
    pesceterian: 'Pesceterian',
    other: 'Other',
    contact: 'Have any questions? contact us!',
    email: '<email>',
    groomsConatctName: 'Bar',
    groomsConatct: '<groomsPhones>',
    bridesContactName: 'Hansol',
    bridesContact: '<bridesPhones>',
    video: '<video>'
};

const ko = {
    title: '알렉산더 & 릴리아의 차남 바 밀리아브스키<br>김명호 & 임선명의 차녀 김한솔<br>저희 결혼합니다',
    // title2: '저희 결혼합니다',
    pretitle: '',
    backupName: '친애하는 손님',
    saveTheDate: '<date>',
    invitation: '저희 두 사람의 출발을 사랑으로 함께 축복해 주시면<br>더 없는 기쁨으로 간직하겠습니다.',
    location: '<locationAdress>',
    food: '음식88: 미국 요리와 전 세계의 놀라움',
    question: '부담갖지 마시고, 참석 의사를 전달해 주시면<br>감사하겠습니다',
    yes: '참석',
    no: '불참석',
    detailsQuestion: '좀 더 자세한 내용을 알려주시겠습니까?',
    numberOfGuests: '참석인원',
    foodPreference: '식사메뉴',
    everything: '모든 것',
    kosher: '코셔(유태인식)',
    vegan: '비건',
    vegetarian: '락토-오보 베지테리언',
    pesceterian: '페스코 베지테리언',
    other: '기타',
    contact: '질문이 있으시다면 부담없이 연락주세요!',
    email: '<email>',
    groomsConatctName: '바 밀리아브스키',
    groomsConatct: '<groomsPhones>',
    bridesContactName: '김한솔',
    bridesContact: '<bridesPhones>',
    video: '<video>',
};

const he = {
    title: 'בר מיליאבסקי והאנסול קים',
    pretitle: 'לחתונה של',
    backupName: 'אורח יקר',
    saveTheDate: '<date>',
    invitation: 'אנו מתרגשים להזמינכם לחתונה שלנו,<br>ושמחים לחלוק אתכם את היום המיוחד הזה.',
    location: '<locationAdress>',
    food: 'אוכל: מטבח אמריקאי עם הפתעות מכל העולם',
    question: 'האם תצטרפו אלינו?',
    yes: 'ברור',
    no: 'לא מסתדר',
    detailsQuestion: 'נהדר!<br> עוד כמה פרטים:',
    numberOfGuests: 'אורחים',
    foodPreference: 'תזונה',
    everything: 'הכל',
    kosher: 'כשר',
    vegan: 'טבעוני',
    vegetarian: 'צמחוני',
    pesceterian: 'צמחודג',
    other: 'אחר',
    contact: 'יש לכם שאלות? צרו קשר!',
    email: '<email>',
    groomsConatctName: 'בר',
    groomsConatct: '<groomsPhones>',
    bridesContactName: 'האנסול',
    bridesContact: '<bridesPhones>',
    video: '<video>'
};

export const getTemplate = (languageProtectedContent) => { 
    const templates = {en, ko, he};
    Object.keys(templates).forEach((language) => { 
        const template = templates[language];
        const protectedContent = languageProtectedContent[language];

        Object.keys(template).forEach((templateKey) => {
            let textWithTemplating = template[templateKey];

            Object.keys(protectedContent).forEach((protectedContentKey) => {
                textWithTemplating = textWithTemplating.replace?.(`<${protectedContentKey}>`, protectedContent[protectedContentKey]);
            });

            template[templateKey] = textWithTemplating;
        });
    });

    return {en, ko, he};
};