import React, { useState, useEffect, useContext } from "react";
import Input from '@mui/joy/Input';
import Button from '@mui/joy/Button';
import HeartIcon from '@mui/icons-material/FavoriteBorder';
import './style.css';
import {getToken} from '../api';
import {LanguageContext} from '../LanguageSelector';
import { template } from "./template";
import TopMenu from "../TopMenu";

function Login({ onLogin }) {
  const {language, dir} = useContext(LanguageContext);
  const languageTemplate = template[language];

  const [username, setUsername] = useState("");
  const [code, setCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowError, setShowError] = useState(false);
  useEffect(() => {
    try {
      const queryString = window.location.search;
      const params = new URLSearchParams(queryString);
      const encodedFields = params.get("prefill");
      if (encodedFields) {
        const decodedFields = atob(encodedFields);
        const fields = new URLSearchParams(decodedFields);
        setUsername(fields.get("user") || "");
        setCode(fields.get("code") || "");
      }
    } catch (error) {
      console.log("wrong query string", error);
    }
  }, []);

  const login = async () => {
    setIsLoading(true);
    setShowError(false);
    try {
      const token = await getToken(username, code);
      onLogin(token);
    }
    catch {
      setShowError(true);
    }

    setIsLoading(false);
  };

  return (
    <>
    <div style={{
      width: "120%",
      height: "120%",
      position: "fixed",
      left: -10,
      right: -10,
      display: "block",
      zIndex: -1,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      filter: "blur(10px)",
      backgroundImage: `url(${
        process.env.PUBLIC_URL + "/Background_cut_out.jpg"
    })`
    }}></div>
    <div style={{
      width: "100%",
      height: "100%",
      textAlign: "center",
      paddingBottom: "5vh",
      boxSizing: "border-box",
      fontSize: "5vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      backgroundSize: "auto 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      boxShadow: "10px 10px",
      filter: "drop-shadow(0px -20px 20px black)",
      backgroundImage: `url(${
        process.env.PUBLIC_URL + "/background_option_2.jpg"
    })`
    }}>
      <TopMenu />
      <div>
        <h1 style={{color: "white", fontFamily: "AnandaBlackPersonalUseRegular", fontSize: "10vh", lineHeight: "1.2"}}>{languageTemplate.broomsName} &<br/>{languageTemplate.bridesName}</h1>
      </div>
      <div className="input-form" dir={dir}>
            <Input error={isShowError} type="text" size='lg' style={{width: "100%", maxWidth: "40vh"}} placeholder={languageTemplate.usernamePlaceholder} disabled={isLoading} value={username} onChange={(e) => setUsername(e.target.value)} />
            <Input error={isShowError} type="text" size='lg' style={{width: "100%", maxWidth: "40vh"}} placeholder={languageTemplate.passwordPlaceholder} disabled={isLoading} value={code} onChange={(e) => setCode(e.target.value)} />
            <Button variant="solid"loading={isLoading} size='lg' endDecorator={<HeartIcon />} dir="ltr" style={{width: "100%", maxWidth: "40vh"}} onClick={login} >{languageTemplate.loginButton}</Button>
        </div>
    </div>
    </>
  );
}

export default Login;
