import React, { useState } from 'react';
import Login from './Login';
import SaveTheDate from './SaveTheDate';
import Stats from './Stats';
import { parseJwt } from './parseJwt';

const Router = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(!!localStorage.getItem("token"));
    const [token, setToke] = useState(localStorage.getItem("token"));

    const handleLogin = (authData) => {
        setIsLoggedIn(true);
        localStorage.setItem("token", authData.data);
        setToke(authData.data);
    };

    const handleLogout = () => {
        setIsLoggedIn(false);
        localStorage.removeItem("token");
        setToke(null);
    };

    if(!isLoggedIn) return <Login onLogin={handleLogin} />;

    const isAdmin = isLoggedIn && token && parseJwt(token)["cognito:groups"]?.includes("app_admins");
    if(isAdmin) return <Stats token={token} onLogout={handleLogout}/>;

    return <SaveTheDate token={token} onLogout={handleLogout}/>;
};

export default Router;