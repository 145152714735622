import axios from 'axios';

export async function getToken(username, password) {
    try {
        const response = await axios.post('https://oov30k2kyk.execute-api.us-east-2.amazonaws.com/default/getToken', {
            username, password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getContent(token) {
    try {
        const response = await axios.get('https://oov30k2kyk.execute-api.us-east-2.amazonaws.com/default/getContent', {
            headers: {
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${token}`,
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getGuestRsvp(token) {
    try {
        const response = await axios.get('https://oov30k2kyk.execute-api.us-east-2.amazonaws.com/default/getGuestRsvp', {
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token}`,
                }
            });

        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function setRsvp(token, rsvp) {
    try {
        const response = await axios.post('https://oov30k2kyk.execute-api.us-east-2.amazonaws.com/default/setGuestRsvp', 
            rsvp, {
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token}`,
                }
            });

        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export async function getGuestsStatuses(token) {
    try {
        const response = await axios.get('https://oov30k2kyk.execute-api.us-east-2.amazonaws.com/default/getGuestsStatus', {
                headers: {
                    'Content-Type': 'application/json', 
                    'Authorization': `Bearer ${token}`,
                }
            });

        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}