import React, {useEffect, useState, useContext} from 'react';
import { LanguageContext } from '../LanguageSelector';
import { getContent, getGuestRsvp, setRsvp } from '../api';
import { getTemplate } from './template';
import Button from '@mui/joy/Button';
import TopMenu from '../TopMenu';
import Divider from '@mui/joy/Divider';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Input from '@mui/joy/Input';
import Link from '@mui/joy/Link';
import theme from '../Theme';
import {LoadingScreen} from './LoadingScreen';
import {HeartComponent} from './HeartDrawing';
import Confetti from 'react-confetti';
import { parseJwt } from '../parseJwt';

const ComingStatus = {
    YES: 'yes',
    NO: 'no',
    UNSURE: 'unsure'
};

const replaceBreakLines = (text) => { 
    const lines = text?.split('<br>');
    return <>{lines?.map((str, i) => <React.Fragment key={i}>{str}{i < lines.length -1? <br/>:null}</React.Fragment>)}</>;
}

const imageUrlByContactImage = {
    "sms.png": `${process.env.PUBLIC_URL}/sms.png`,
    "kakaotalk.png": `${process.env.PUBLIC_URL}/kakaotalk.png`,
    "whatsapp.png": `${process.env.PUBLIC_URL}/whatsapp.png`,
}

const fontByLanguage = {
    en: 'Moderustic',
    he: 'VarelaRound-Regular',
    ko: 'Cafe24Oneprettynight'
}
const SaveTheDate = ({token, onLogout}) => {
    const {language, dir} = useContext(LanguageContext);
    const [isComing, setIsComing] = useState(null);
    const [showConfety, setShowConfety] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [content, setContent] = useState(null);
    const [errorState, setErrorState] = useState(false);
    const [numberOfGuests, setNumberOfGuests] = useState(1);
    const [foodPreference, setFoodPreference] = useState('everything');

    const font = fontByLanguage[language];

    const parsedToken = parseJwt(token);
    const name = parsedToken.name;

    useEffect(() => {
        const intervalId = setInterval(() => {
            onLogout();
        }, parsedToken.exp * 1000 - Date.now());

        return () => clearInterval(intervalId);
    }, [parsedToken, onLogout, token]);

    useEffect(() => {
        Promise.all([getContent(token), getGuestRsvp(token)]).then(([content, rsvp]) => {
            setIsComing(rsvp.rsvpStatus);
            setNumberOfGuests(rsvp.guestCount);
            setFoodPreference(rsvp.foodStatus);
            setContent(content);
            setIsLoading(false);
        }).catch((err) => {
            if(err.response.status === 401) {
                onLogout();
            }
            setErrorState(true);
        });
    }, [token, onLogout]);

    useEffect(() => {
        (async () => {
        if(!isComing) return;
        setIsLoading(true);
        try {
            await setRsvp(token, {
                rsvpStatus: isComing, 
                guestCount: numberOfGuests, 
                foodStatus: foodPreference
            })
        }
        catch {}
        finally {
            setIsLoading(false);
        }})();
    }, [token, isComing, numberOfGuests, foodPreference]);


    const contentByLanguage = content?.[language];
    const template = content? getTemplate(content)[language]: {};

    if(!token || parsedToken.exp * 1000 < Date.now()) {
        onLogout();
        return null;
    }

    if(errorState) {
        return <div>Something went wrong, please refresh</div>;
    }

    return (
        <div dir={dir} style={{ 
            backgroundPosition: "center",
            backgroundRepeat: "repeat",
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            backgroundImage: `url(${
                process.env.PUBLIC_URL + "/wallpaper.png"
            })`,
            backgroundColor: theme.palette.primary[100],
            backgroundSize: "200px",
            fontFamily: font,
        }}>
            <TopMenu onLogout={() => onLogout()} />
            {showConfety && <Confetti recycle={false}/>}
            <div dir={dir} style={{ 
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxSizing: "border-box",
                    overflowY: "scroll",
                    overflowX: "hidden",
                }}>

                <div style={{
                        marginTop: "7vh",
                        marginBottom: "7vh",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        maxWidth: "600px",
                        width: "90%",
                        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.5)",
                        backgroundImage: `url(${
                            process.env.PUBLIC_URL + "/paper_background.jpg"
                        })`,
                        backgroundSize: "100% auto",
                }}>
                { (!template || !content )? (
                    <LoadingScreen />
                ) :
                <>
                <div style={{ fontSize: "4vh", lineHeight: "1.2", marginTop: "5vh", marginBottom: "3vh"}}>
                    {language !== 'ko'? <>{contentByLanguage?.month} {contentByLanguage?.year}</>: <>{contentByLanguage?.year} {contentByLanguage?.month}</>}
                </div>
                <div style={{ display: "grid", width: "100%"}}>
                    {contentByLanguage?.daysOfWeek?.map((day, i) => (
                        <div key={day} style={{gridColumn: i + 1, gridRow: 1, textAlign: "center", marginBottom: "2vh"}}>{day}</div>
                    ))}
                    {contentByLanguage?.datesOfDays?.map((day, i) => (
                        <div key={day} style={{gridColumn: i + 1, gridRow: 2, textAlign: "center"}}>{day !== '22'? day: <div style={{ position: 'relative', display: 'inline-block' }}>
                        <div style={{ position: 'absolute', top: "-1.1em", left: '50%', transform: 'translateX(-50%)', width: "3.3em", height: "3.3em" }}>
                            <HeartComponent />
                        </div>
                        <p style={{ margin: 0 }}>{day}</p>
                    </div> }</div>
                    ))}
                </div>
                <div style={{ 
                    width: "90%",
                    backgroundImage: `url(${
                                process.env.PUBLIC_URL + "/engagmentPhoto.jpg"
                            })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover", 
                    backgroundPosition: "40% 100%",
                    height: "20em",
                    marginBottom: "2vh",
                    marginTop: "2vh",
                    boxShadow: "inset 0px 0px 20px rgba(0, 0, 0, 0.9)",
                    display: "flex"
                    }}>
                    <h1 style={{
                        alignSelf: "flex-end",
                        fontFamily: "DarlingtonDemo", 
                        fontSize: "4em", 
                        width: "100%",
                        textAlign: "center",
                        position: "relative",
                        bottom: "-0.35em",
                        color: theme.palette.primary[400],
                        margin: "0",
                        textShadow: "1px 1px 0.1em black"
                        }}
                        >
                            Save The Date
                        </h1>
                </div>
                <div style={{ fontSize: "1.2em", lineHeight: "1.2", marginTop: "2vh", marginLeft: "1em", marginRight: "1em"}}>
                    {replaceBreakLines(template.pretitle)}
                </div>
                <div style={{ fontSize: "1.5em", lineHeight: "1.2", margin: "1vh", textAlign: "center", marginLeft: "0.5em", marginRight: "0.5em"}}>
                    <strong>{replaceBreakLines(template.title)}</strong>
                </div>
                <div style={{textAlign: "center", margin: "1vh", marginLeft: "1em", marginRight: "1em"}}>
                    <div>{template?.saveTheDate}</div>
                    <div>{template?.location}</div>
                </div>
                <p style={{textAlign: "center", marginLeft: "1em", marginRight: "1em"}}>
                    {name?? replaceBreakLines(template.backupName)},<br/>
                    {replaceBreakLines(template.invitation)}
                </p>
                <p style={{marginBlockEnd: "0.5em", textAlign: 'center'}}>{replaceBreakLines(template.question)}</p>
                <div style={{display: "flex", flexDirection: "row", width: "90%"}}>
                    <Button variant={isComing === ComingStatus.YES? "solid" : "outlined"} loading={isLoading} size='lg' style={{width: "100%", maxWidth: "40vh", margin: "5px", fontFamily: font }} onClick={() => {
                        setIsComing(ComingStatus.YES)
                        setShowConfety(true);
                    }} >{template.yes}</Button>
                    <Button variant={isComing === ComingStatus.NO? "solid" : "outlined"} loading={isLoading} size='lg' style={{width: "100%", maxWidth: "40vh", margin: "5px", fontFamily: font }} onClick={() => setIsComing(ComingStatus.NO)} >{template.no}</Button>
                </div>
                {
                    isComing === ComingStatus.YES && <>
                        <p style={{ fontSize: "1.5em", lineHeight: "1.2", margin: "1em", marginBlockEnd: "0.5em", textAlign: "center"}}>
                            {replaceBreakLines(template.detailsQuestion)}
                        </p>
                        <div style={{display: "flex", flexDirection: "row", width: "90%"}}>
                            <Input 
                                disabled={isLoading}
                                style={{width: "100%", maxWidth: "40vh", margin: "5px", fontFamily: font}}
                                type="number" 
                                startDecorator={`${template.numberOfGuests}:`}
                                value={numberOfGuests} 
                                onClick={(e) => e.target?.select?.()}
                                onChange={(e) => { 
                                    const value = e.target.value;
                                    if(value > 1 && value < 10) {
                                        setNumberOfGuests(value);
                                        return;
                                    }
                                    setNumberOfGuests(1);
                                }}/>
                            <Select 
                                disabled={isLoading}
                                style={{width: "100%", maxWidth: "40vh", margin: "5px", fontFamily: font  }}
                                startDecorator={`${template.foodPreference}: `}
                                onChange={(_, value) => {
                                    setFoodPreference(value)}} 
                                value={foodPreference} >
                                <Option value="everything">{template.everything}</Option>
                                <Option value="kosher">{template.kosher}</Option>
                                <Option value="vegan">{template.vegan}</Option>
                                <Option value="vegeterian">{template.vegetarian}</Option>
                                <Option value="pesceterian">{template.pesceterian}</Option>
                                <Option value="other">{template.other}</Option>
                            </Select>
                        </div>
                    </>
                }
                <video 
                    style={{ margin: "2em" }}
                    width="90%" 
                    controls 
                    src={template.video} 
                    backgroundcolor="Black" 
                    poster={`${process.env.PUBLIC_URL}/video_poster_fake_conrols.png`} />

                <Divider style={{ marginBlockEnd: "1em"}}/>
                <div style={{textAlign: "center", marginBlockEnd: "5vh"}}>
                    <div>{template?.contact}</div>
                    <Link href={`mailto:${template?.email}`}>{template?.email}</Link>
                    <div>{template?.groomsConatctName}: {contentByLanguage?.groomsPhones?.map((x, i) => <Link key={`groom-number-${i}`} startDecorator={<img style={{height: "1em"}} src={imageUrlByContactImage[x.img]} alt={x.img} />} style={{ margin: "0 0.5em 0 0.5em"}} target='_blank' dir='ltr' href={x.link}>{x.value}</Link>)}</div>
                    <div>{template?.bridesContactName}: {contentByLanguage?.bridesPhones?.map((x, i) => <Link key={`bride-number-${i}`} startDecorator={<img style={{height: "1em"}} src={imageUrlByContactImage[x.img]} alt={x.img} />} style={{ margin: "0 0.5em 0 0.5em"}} target='_blank' dir='ltr' href={x.link}>{x.value}</Link>)}</div>
                </div>
                </>
                }
                </div>
            </div>
        </div>);
};

export default SaveTheDate;
